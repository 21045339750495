<template>
  <div v-if="selectedUsuario">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <b-card>
        <usuariosForm
          btnSubmit="Editar Usuario"
          processType="updated"
          :usuario="selectedUsuario"
          @processForm="editar"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import { BCard, BOverlay } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import usuariosForm from './components/UsuariosForm.vue'

export default {
  components: {
    BCard,
    usuariosForm,
    BOverlay,
  },
  data() {
    return {
      spinner: false,
      usuario: [],
    }
  },
  computed: {
    ...mapState('usuarios', ['selectedUsuario']),
    ...mapState('auth', ['user']),
    ...mapGetters({
      getDocenteAsignaturas: 'usuarios/getDocenteAsignaturas',
    }),
  },
  mounted() {
    this.setAsignaturasDocente()
  },
  methods: {
    ...mapActions({
      updateUsuarios: 'usuarios/updateUsuarios',
      attempt: 'auth/attempt',
      fetchDocenteAsignaturas: 'usuarios/fetchDocenteAsignaturas',
    }),
    setAsignaturasDocente() {
      const { nombreRol } = this.selectedUsuario
      if (nombreRol === 'Docente'
        || nombreRol === 'Docente Pie'
        || nombreRol === 'Asistente') {
        // consultar asignaturas del docente y agregarlas en asignaturas
        const data = {
          id: this.selectedUsuario.id,
          idEstablecimientoActivo: this.user.idEstablecimientoActivo,
        }
        this.fetchDocenteAsignaturas(data).then(() => {
          this.selectedUsuario.asignaturas = []
          this.getDocenteAsignaturas.forEach(docenteAsignatura => {
            this.selectedUsuario.asignaturas.push({
              idAsignatura: docenteAsignatura.id,
              idCurso: docenteAsignatura.idCurso,
              idGrado: docenteAsignatura.idGrado,
            })
          })
        })
      }
    },
    editar(usuario) {
      this.spinner = true
      const datos = {
        usuario,
        idEstablecimiento: this.user.idEstablecimientoActivo,
      }
      this.updateUsuarios(datos).then(() => {
        const errorUsuarios = store.state.usuarios
        const errorMessage = errorUsuarios.errorMessage.errors
        if (!errorUsuarios.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Usuario editado 👍',
              text: `El usuario "${usuario.nombres} ${usuario.primerApellido}
                ${usuario.segundoApellido}" fue editado con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          if (this.user.id === usuario.id) {
            this.attempt(this.token)
          }
          this.$router.replace({
            name: 'usuarios',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorUsuarios.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
